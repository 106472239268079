import {XHTTP} from '@/shared/xhttp';
import {AvisLoyerService} from "@/xhttp";
import {DateHelper} from "@/shared/utils";
import {Locataire, TermType, TvaType, SuiviReglement} from "@/models";
import {Tva} from "@/models/Tva";

export type StateAvisLoyerType = 'Généré automatiquement' | 'Validé' | 'Payé';
export const StateAvisLoyerType = {
	GENERATED: 'StateAvisLoyerType' as StateAvisLoyerType,
	VALIDATED: 'Validé' as StateAvisLoyerType,
	PAID: 'Payé' as StateAvisLoyerType,
};

@XHTTP(AvisLoyerService, '/avis-loyer')
export class AvisLoyer {

	private _id: number;
	private _numberQuittance: string;
	private _date: Date;
	private _locataire: Locataire;
	private _quittanceAvisLoyer: StateAvisLoyerType = StateAvisLoyerType.GENERATED;
	private _numberAvisLoyer: number;
	private _dateFrom: Date;
	private _dateTo: Date;
	private _correspondance: string;
	private _quittance: boolean;
	private _amountRental: number;
	private _amountAdvances: number;
	private _amountAnnualAdjustment: number;
	private _tvaType: TvaType = TvaType.HT;
	private _termType: TermType = TermType.ECHU;
	private _tva: Tva;
	private _transferCaf: number;
	private _reglements: SuiviReglement[] = [];
	private _total: number = 0.0;
	private _montantAPayer: number = 0.0;
	private _resteAPayer: number = 0.0;
	private _createdAt: Date;

	/////////////
	// Getters //
	/////////////

	public get id(): number {
		return this._id;
	}

	public get numberQuittance(): string {
		return this._numberQuittance;
	}

	public get date(): Date {
		return this._date;
	}

	public get locataire(): Locataire {
		return this._locataire;
	}

	public get stateAvisLoyer(): StateAvisLoyerType {
		return this._quittanceAvisLoyer ? <any>this._quittanceAvisLoyer.toString() : null;
	}

	public get numberAvisLoyer(): number {
		return this._numberAvisLoyer;
	}

	public get dateFrom(): Date {
		return this._dateFrom;
	}

	public get dateTo(): Date {
		return this._dateTo;
	}

	public get correspondance(): string {
		return this._correspondance;
	}

	public get quittance(): boolean {
		return this._quittance;
	}

	public get amountRental(): number {
		return this._amountRental;
	}

	public get amountAdvances(): number {
		return this._amountAdvances;
	}

	public get amountAnnualAdjustment(): number {
		return this._amountAnnualAdjustment;
	}

	public get tvaType(): TvaType {
		return this._tvaType ? <any>this._tvaType.toString() : null;
	}

	public get termType(): TermType {
		return this._termType ? <any>this._termType.toString() : null;
	}

	public get tva(): Tva {
		return this._tva;
	}

	public get transferCaf(): number {
		return this._transferCaf;
	}

	public get reglements(): SuiviReglement[] {
		return this._reglements;
	}

	public get total(): number {
		return this._total;
	}

	public get montantAPayer(): number {
		return this._montantAPayer;
	}

	public get resteAPayer(): number {
		return this._resteAPayer;
	}

	public get createdAt(): Date {
		return this._createdAt;
	}

	/////////
	// Add //
	/////////

	/////////////
	// Setters //
	/////////////

	public set id(value: number) {
		this._id = value;
	}


	public set numberQuittance(value: string) {
		this._numberQuittance = value;
	}

	public set date(value: Date) {
		this._date = value;
	}

	public set locataire(value: Locataire) {
		this._locataire = value;
	}

	public set stateAvisLoyer(value: StateAvisLoyerType) {
		this._quittanceAvisLoyer = value;
	}

	public set numberAvisLoyer(value: number) {
		this._numberAvisLoyer = value;
	}

	public set dateFrom(value: Date) {
		this._dateFrom = value;
	}

	public set dateTo(value: Date) {
		this._dateTo = value;
	}

	public set correspondance(value: string) {
		this._correspondance = value;
	}

	public set quittance(value: boolean) {
		this._quittance = value;
	}

	public set amountRental(value: number) {
		this._amountRental = value;
		this.updatePrice();
	}

	public set amountAdvances(value: number) {
		this._amountAdvances = value;
		this.updatePrice();
	}

	public set amountAnnualAdjustment(value: number) {
		this._amountAnnualAdjustment = value;
		this.updatePrice();
	}

	public set tvaType(value: TvaType) {
		this._tvaType = value;
	}

	public set termType(value: TermType) {
		this._termType = value;
	}

	public set tva(value: Tva) {
		this._tva = value;
	}

	public set transferCaf(value: number) {
		this._transferCaf = value;
		this.updatePrice();
	}

	public set reglements(value: SuiviReglement[]) {
		this._reglements = value;
	}

	public set total(value: number) {
		this._total = value;
	}

	public set montantAPayer(value: number) {
		this._montantAPayer = value;
	}

	public set resteAPayer(value: number) {
		this._resteAPayer = value;
	}

	public set createdAt(value: Date) {
		this._createdAt = value;
	}

	public updatePrice(): void {
		var total = this.amountRental + this.amountAdvances + this.amountAnnualAdjustment;
		this.montantAPayer = total - this.transferCaf;
		this.total = total;

	}

	///////////////
	// Serialize //
	///////////////

	public toJSON(): any {
		return {
			id: this._id,
			numberQuittance: this._numberQuittance,
			date: this._date ? DateHelper.toLocalizedString(this._date) : null,
			locataire: this._locataire ? this._locataire.id : null,
			quittanceAvisLoyer: this._quittanceAvisLoyer ? this._quittanceAvisLoyer.toString() : null,
			numberAvisLoyer: this._numberAvisLoyer,
			dateFrom: this._dateFrom ? DateHelper.toLocalizedString(this._dateFrom) : null,
			dateTo: this._dateTo ? DateHelper.toLocalizedString(this._dateTo) : null,
			correspondance: this._correspondance,
			quittance: this._quittance,
			state: this._quittance,
			amountRental: this._amountRental,
			amountAdvances: this._amountAdvances,
			amountAnnualAdjustment: this._amountAnnualAdjustment,
			tvaType: this._tvaType ? this._tvaType.toString() : null,
			termType: this._termType ? this._termType.toString() : null,
			tva: this._tva ? this._tva.id : null,
			transferCaf: this._transferCaf,
			suiviReglements: this._reglements ? this._reglements.map(r => r.toJSON()) : [],
			total: this._total,
			montantAPayer: this._montantAPayer,
			resteAPayer: this._resteAPayer,
		};
	}

	public fromJSON(data: any): this {
		this._id = data.id
		this._numberQuittance = data.numberQuittance;
		this._date = data.date ? new Date(data.date) : null;
		this._locataire = data.locataire ? (new Locataire()).fromJSON(data.locataire) : null;
		this._quittanceAvisLoyer = data.stateAvisLoyer;
		this._numberAvisLoyer = data.numberAvisLoyer;
		this._dateFrom = data.dateFrom ? new Date(data.dateFrom) : null;
		this._dateTo = data.dateTo ? new Date(data.dateTo) : null;
		this._correspondance = data.correspondance;
		this._quittance = data.quittance;
		this._amountRental = data.amountRental;
		this._amountAdvances = data.amountAdvances;
		this._amountAnnualAdjustment = data.amountAnnualAdjustment;
		this._tvaType = data.tvaType;
		this._termType = data.termType;
		this._tva = data.tva ? (new Tva()).fromJSON(data.tva) : null;
		this._transferCaf = data.transferCaf;
		this._reglements = data.suiviReglements ? data.suiviReglements.map(r => (new SuiviReglement()).fromJSON(r)) : [];
		this._total = data.total;
		this._montantAPayer = data.montantAPayer;
		this._resteAPayer = data.resteAPayer;
		this._createdAt = data.createdAt ? new Date(data.createdAt) : null;
		return this;
	}
}
